import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import {selectFilter, selectSort, setFilter, setSort} from "../DataTable/dataTableSlice.ts";
import ToolbarAddButton from '../Shared/ToolbarAddButton';
import {ProductEditorDialog} from './ProductEditorDialog';
import {useGetProductsQuery} from '../../api.ts';
import {createQueryString} from "../../utils";
import {numericFilterOperators, stringFilterOperators} from "../DataTable/utils.ts";
import SeverityPill from "../Shared/SeverityPill";

const CustomToolbar = (toggleProductEditorDialog) => () => {
    return (
        <GridToolbarContainer>
            <ToolbarAddButton action={toggleProductEditorDialog}/>
        </GridToolbarContainer>
    );
}

const Products = () => {
    const sort = useSelector(selectSort('products'));
    const filter = useSelector(selectFilter('products'));
    const [selectedProductId, setSelectedProductId] = useState();
    const [editorOpen, setEditorOpen] = useState(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedProductId(undefined);
    }

    const openEditorDialog = (id) => {
        setSelectedProductId(id);
        setEditorOpen(true);
    }

    const columns = React.useMemo(
        () => [
            {field: 'id', headerName: 'ID', width: 120, filterOperators: numericFilterOperators},
            {field: 'externalId', headerName: 'External Id'},
            {field: 'name', headerName: 'Name', width: 250, filterOperators: stringFilterOperators},
            {field: 'description', headerName: 'Description', flex: 1, filterOperators: stringFilterOperators},
            {field: 'createdAt', headerName: 'Created At'},
            {
                field: "inventorySize",
                headerName: "Inventory",
                renderCell: (params) => {
                    return (
                        <SeverityPill
                            color={((parseInt(params.value) >= 100) && 'success')
                                || ((parseInt(params.value) <= 50) && 'error')
                                || 'warning'}
                        >
                            {params.value}
                        </SeverityPill>
                    );
                }
            },
            {
                field: 'actions', type: 'actions', headerName: '', width: 10, getActions: (params) => [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        onClick={() => openEditorDialog(params.id)}
                        showInMenu
                    />
                ]
            }
        ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({tableId: 'products', filter: filterValue}));
        }, 1000)

        return () => clearTimeout(timer)
    }, [filterValue])

    const queryString = React.useMemo(() => createQueryString(sort, filter), [sort, filter]);
    const {data, isFetching} = useGetProductsQuery(queryString);

    let selectedProduct = undefined;

    if (data && setSelectedProductId) {
        const products = data.content;
        selectedProduct = products.find((element) => element.id === selectedProductId);
    }

    return (
        <React.Fragment>
            <ProductEditorDialog
                open={editorOpen}
                onClose={closeEditorDialog}
                product={selectedProduct}
            />
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSize={data?.number || 0}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({tableId: 'products', sort: params}))
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                            id: false,
                        },
                    }
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
}

export default Products;
