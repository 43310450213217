import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useCreateLocationMutation, useUpdateLocationMutation} from "../../api.ts";

const validationSchema = yup.object({
    name: yup
        .string()
        .required("Name is required"),
});

export const LocationEditorDialog = ({
                                  open,
                                  location,
                                  onClose,
                              }) => {

    const [createLocation, createResult] = useCreateLocationMutation();
    const [updateLocation, updateResult] = useUpdateLocationMutation();

    const isLoading = createResult.isLoading || updateResult.isLoading;

    const formik = useFormik({
        initialValues: {
            id: location?.id || '',
            name: location?.name || '',
            description: location?.description || '',
            externalId: location?.externalId || '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (values.id) {
                updateLocation(values);
            } else {
                createLocation(values);
            }
            onClose();
            resetForm();
        },
    });

    const {handleSubmit, handleChange, values, isValid, errors} = formik;

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>Add Location</DialogTitle>
            <DialogContent>
                <Box sx={{mt: 1}} component="form" onSubmit={handleSubmit} onChange={handleChange}>
                    <fieldset disabled={isLoading}>
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    value={values.name}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    value={values.description}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="externalId"
                                    label="External Id"
                                    value={values.externalId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item sm={12} justifyContent="flex-end">
                                <Button variant="secondary" onClick={onClose} disabled={isLoading} sx={{mr: 2}}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type="submit" disabled={isLoading || !isValid}>
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Box>
            </DialogContent>
        </Dialog>
    )
};
