import * as yup from 'yup';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import Autocomplete from '../Shared/AutoComplete';

import { useFormik } from 'formik';
import {
    useCreateProductInventoryCheckoutMutation,
} from "../../api.ts";

export const ProductInventoryCheckoutDialog = ({
                                          productInventory,
                                          open,
                                          onClose,
                                      }) => {

    const [createProductInventoryCheckout, createResult] = useCreateProductInventoryCheckoutMutation();

    const isLoading = createResult.isLoading;

    const isEdit = Boolean(productInventory);
    const dialogTitle = isEdit ? "Edit" : "Add";

    const {
        values,
        touched,
        errors,
        isValid,
        setFieldValue,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            productInventoryId: productInventory?.id || '',
            product: productInventory?.product || null,
            location: productInventory?.location || null,
            customerId: '',
            orderId: '',
            size: productInventory?.size || 0,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            createProductInventoryCheckout(values);
            onClose();
            resetForm();
        }
    });

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>{`Checkout Product Inventory`}</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }} component="form" onSubmit={handleSubmit} onChange={handleChange}>
                    <fieldset disabled={isLoading}>
                        <Grid container rowSpacing={3}>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="product"
                                    label="Product"
                                    queryItems={() => {}}
                                    error={touched.product && Boolean(errors.product)}
                                    helperText={touched.size && errors.product}
                                    onChange={setFieldValue}
                                    disabled={true}
                                    value={values?.product}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="location"
                                    label="Location"
                                    queryItems={() => {}}
                                    error={touched.location && Boolean(errors.location)}
                                    helperText={touched.size && errors.location}
                                    onChange={setFieldValue}
                                    disabled={true}
                                    value={values?.location}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="customerId"
                                    label="Customer"
                                    type="string"
                                    value={values?.customerId}
                                    error={touched.customerId && Boolean(errors.customerId)}
                                    helperText={touched.customerId && errors.customerId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="orderId"
                                    label="Order"
                                    type="string"
                                    value={values?.orderId}
                                    error={touched.orderId && Boolean(errors.orderId)}
                                    helperText={touched.orderId && errors.orderId}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="size"
                                    label="Size"
                                    type="number"
                                    value={values?.size}
                                    error={touched.size && Boolean(errors.size)}
                                    helperText={touched.size && errors.size}
                                    fullWidth
                                />
                            </Grid>
                            <Grid container item sm={12} justifyContent="flex-end">
                                <Button variant="secondary" onClick={onClose} disabled={isLoading} sx={{ mr: 2 }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type="submit" disabled={isLoading || !isValid}>
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Box>
            </DialogContent>
        </Dialog>
    )
};

const validationSchema = yup.object({
    customerId: yup
        .string()
        .nullable()
        .required("Location is required"),
    orderId: yup
        .string()
        .nullable()
        .required("Product is required"),
    size: yup
        .number()
        .required("Size is required"),
});
