import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import {selectFilter, selectSort, setFilter, setSort} from "../DataTable/dataTableSlice.ts";
import ToolbarAddButton from '../Shared/ToolbarAddButton';
import {useGetProductInventoryCheckoutsQuery} from '../../api.ts';
import {createQueryString} from "../../utils";
import {numericFilterOperators, stringFilterOperators} from "../DataTable/utils.ts";
import SeverityPill from "../Shared/SeverityPill";

const CustomToolbar = (toggleProductEditorDialog) => () => {
    return (
        <GridToolbarContainer>
            <ToolbarAddButton action={toggleProductEditorDialog}/>
        </GridToolbarContainer>
    );
}

const ProductInventoryCheckouts = () => {
    const sort = useSelector(selectSort('products'));
    const filter = useSelector(selectFilter('products'));
    const [selectedProductInventoryId, setSelectedProductInventoryId] = useState();
    const [editorOpen, setEditorOpen] = useState(false);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState(filter);

    const closeEditorDialog = () => {
        setEditorOpen(false);
        setSelectedProductInventoryId(undefined);
    }

    const openEditorDialog = (id) => {
        setSelectedProductInventoryId(id);
        setEditorOpen(true);
    }

    const columns = React.useMemo(
        () => [
            {field: 'id', headerName: 'ID', width: 120, filterOperators: numericFilterOperators},
            {field: 'productInventory.product.name', headerName: 'Product', valueGetter: (params) => params.row.productInventory.product.name, flex: 0.5, filterOperators: stringFilterOperators},
            {field: 'productInventory.location.name', headerName: 'Location', valueGetter: (params) => params.row.productInventory.location.name, flex: 0.5, filterOperators: stringFilterOperators},
            {field: 'customerId', headerName: 'Customer', width: 200, filterOperators: stringFilterOperators},
            {field: 'orderId', headerName: 'Order', width: 200, filterOperators: stringFilterOperators},
            {
                field: "size",
                headerName: "Checkout",
                renderCell: (params) => {
                    return (
                        <SeverityPill
                            color={((parseInt(params.value) >= 100) && 'success')
                                || ((parseInt(params.value) <= 50) && 'error')
                                || 'warning'}
                        >
                            {params.value}
                        </SeverityPill>
                    );
                }
            },
        ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setFilter({tableId: 'products', filter: filterValue}));
        }, 1000)

        return () => clearTimeout(timer)
    }, [filterValue])

    const queryString = React.useMemo(() => createQueryString(sort, filter), [sort, filter]);
    const {data, isFetching} = useGetProductInventoryCheckoutsQuery(queryString);

    let selectedProductInventory = undefined;

    if (data && selectedProductInventoryId) {
        const productInventories = data.content;
        selectedProductInventory = productInventories.find((element) => element.id === selectedProductInventoryId);
    }

    return (
        <React.Fragment>
            <DataGrid
                filterModel={filterValue}
                sortModel={sort}
                rows={data?.content || []}
                columns={columns}
                pageSize={data?.number || 0}
                sortingMode="server"
                onSortModelChange={(params) => {
                    dispatch(setSort({tableId: 'products', sort: params}))
                }}
                filterMode="server"
                onFilterModelChange={setFilterValue}
                loading={isFetching}
                autoPageSize
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            createdAt: false,
                        },
                    }
                }}
                slots={{
                    toolbar: CustomToolbar(openEditorDialog),
                }}
            />
        </React.Fragment>
    );
}

export default ProductInventoryCheckouts;
