import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store.ts'

export interface TableState {
    filter?: any,
    sort?: any,
}

export interface TableStateStore {
    [key: string]: TableState;
}

const initialState: TableStateStore = {
    locations: {
        sort: [{field: "createdAt", sort: "desc"}]
    },
    products: {
        sort: [{field: "createdAt", sort: "desc"}]
    }
}

export const dataTableSlice = createSlice({
    name: 'dataTable',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<any>) => {
            const { tableId, filter } = action.payload;
            state[tableId] = { ...state[tableId], filter: filter };
        },
        setSort: (state, action: PayloadAction<any>) => {
            const { tableId, sort } = action.payload;
            state[tableId] = { ...state[tableId], sort: sort };
        },
    },
})

export const { setFilter, setSort } = dataTableSlice.actions

export const selectFilter = (tableId: string) => (state: RootState) => state.dataTable[tableId]?.filter
export const selectSort = (tableId: string) => (state: RootState) => state.dataTable[tableId]?.sort
