import React, {useEffect} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setCredentials} from './authSlice.ts';
import {useGetTokenQuery} from '../../api.ts';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Authenticated() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let query = useQuery();

    const {data, isSuccess} = useGetTokenQuery(query.get("code"));

    useEffect(() => {
    if (isSuccess) {
        dispatch(setCredentials(data));
        navigate("/");
    }}, [isSuccess]);

    return (
        <Grid container spacing={2} height={"100%"}>
            <Grid xs={12} align="center">
                <CircularProgress/>
            </Grid>
            <Grid xs={12} align="center">
                Please wait ...
            </Grid>
        </Grid>);
}

export default Authenticated;
