import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const ToolbarAddButton = ({ action }) => (
    <Tooltip title="Add">
        <IconButton onClick={action}>
            <AddIcon />
        </IconButton>
    </Tooltip>
);

export default ToolbarAddButton;