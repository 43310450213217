import AuthorizedRoute from './components/Auth/AuthorizedRoute';
import Authenticated from './components/Auth/Authenticated';
import {BrowserRouter, createBrowserRouter, Navigate, Route, RouterProvider, Routes} from 'react-router-dom';
import Container from './components/Container';
import Account from "./components/Account/Account";
import Products from "./components/Products/Products";
import ProductInventories from "./components/ProductInventories/ProductInventories";
import Locations from "./components/Locations/Locations";
import RouteNotFound from "./components/Shared/RouteNotFound";
import Dashboard from "./components/Dashboard/Dashboard";
import * as React from "react";
import ProductInventoryCheckouts from "./components/ProductInventoryCheckouts/ProductInventoryCheckouts";

// 3️⃣ Router singleton created
const router = createBrowserRouter([
    { path: "/authorized", Component: Authenticated },
    { path: "/*",
        element: <AuthorizedRoute><Container/></AuthorizedRoute>,
        children: [{
            path: "account",
            element: <Account/>,
        },
            {
                path: "products",
                element: <Products />,
            },
            {
                path: "products/:productId/inventories",
                key:"productInventories",
                element: <ProductInventories />,
            },
            {
                path: "products/inventories",
                element: <ProductInventories />,
            },
            {
                path: "products/inventories/checkouts",
                element: <ProductInventoryCheckouts />,
            },
            {
                path: "locations",
                element: <Locations />,
            },
            {
                path: "",
                element: <Dashboard />
            }
        ]
    },
    { path: "*", Component: RouteNotFound },
]);

// 4️⃣ RouterProvider added
export default function App() {
    return <RouterProvider router={router} />;
}
