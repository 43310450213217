import * as React from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import throttle from 'lodash/throttle';

const AutoComplete = ({
  id,
  label,
  queryItems,
  error,
  value,
  onChange,
  disabled,
  helperText
}) => {

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const onInputChange = React.useMemo(() => throttle(queryItems, 500), [queryItems]);

  React.useEffect(() => {
    let active = true;

    if (disabled) return;

    const queryAndSetOptions = ({ queryString }) => {
      onInputChange(queryString).then((response) => {
        let items = response.data.content;
        if (active) {
          setOptions(items);
        }
      });
    };

    let queryString = `name=${inputValue}`;

    queryAndSetOptions({ queryString });

    return () => {
      active = false;
    };
  }, [disabled, inputValue, onInputChange]);

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => `${option.id} - ${option.name}`}
      options={options}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption' || reason === 'clear') {
          onChange(id, newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={value ? `${value.id} - ${value.name}` : label}
          fullWidth
          error={Boolean(error)}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography>
                  {`${option.id} - ${option.name}`}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default AutoComplete;