import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const AccountForm = ({ values, errors, handleChange, handleSubmit, isValid, isSubmitting }) => (
    <Paper>
        <Box component="form" onSubmit={handleSubmit} onChange={handleChange} m={2} p={2}>
            <fieldset disabled={isSubmitting}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="firstName"
                            label="First Name"
                            value={values.firstName}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="lastName"
                            label="Last Name"
                            value={values.lastName}
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="email"
                            label="Email"
                            type="email"
                            value={values.email}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="phone"
                            label="Phone"
                            type="number"
                            value={values.phone}
                            error={Boolean(errors.phone)}
                            helperText={errors.phone}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item sm={12} justifyContent="flex-end">
                        <Button variant="contained" type="submit" disabled={isSubmitting || !isValid}>
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </fieldset>
        </Box>
    </Paper>
);

const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('First Name is required'),
    lastName: yup
        .string()
        .required('Last Name is required'),
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    phone: yup
        .number()
        .required('Phone is required'),
});

export default withFormik({
    mapPropsToValues: (props) => ({
        firstName: props.firstName || '',
        lastName: props.lastName || '',
        email: props.email || '',
        phone: props.phone || ''
    }),
    validationSchema: validationSchema,
    validateOnMount: true,
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
        //setSubmitting(false);
        // handle submit
    }
})(AccountForm);