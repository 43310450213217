import HomeIcon from '@mui/icons-material/Home';
import { Link as MaterialLink } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const getLink = ({ match, breadcrumb, location }) => {
  return (
    <MaterialLink
      underline="none"
      sx={{ display: 'flex', alignItems: 'center' }}
      color="inherit"
      component={location.pathname !== match.path ? Link : null}
      key={match.pathname}
      to={match.pathname}
    >
      {(match.path === '/') && <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
      {breadcrumb}
    </MaterialLink>
  );
}

const _Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb" sx={{ mt:2, mb: 2, ml: 2 }}>
      {breadcrumbs.map(getLink)}
    </Breadcrumbs>
  );
}

export default _Breadcrumbs;