export const APP_NAME = 'Scarlet';
export const APP_VER = '0.0.1';
export const AUTH_ENDPOINT = 'https://core.naveen.me'
export const API_ENDPOINT = 'https://core.naveen.me';

export const GLOBAL_DEPARTMENT_ID = 1;

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_STUDENT = 'ROLE_STUDENT';
export const ROLE_FACULTY = 'ROLE_FACULTY';

export const ROLES = [
  { id: 'STUDENT', label: 'Student' },
  { id: 'FACULTY', label: 'Faculty' },
  { id: 'ADMIN', label: 'Admin' }];

export const ROLENAMES = {
  STUDENT: 'STUDENT',
  FACULTY: 'FACULTY',
  ADMIN: 'ADMIN',
};