import { AUTH_ENDPOINT } from '../../constants';
import { useAuth } from '../../hooks/useAuth.ts'

const AuthorizedRoute = ({ children }) => {
  const auth = useAuth()

  if (!auth.userId) {
    window.location.href = `${AUTH_ENDPOINT}/oauth2/authorize?client_id=webapp&response_type=code`;
    return null;
  }

  return children;
};

export default AuthorizedRoute;