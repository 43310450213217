import {
    Box,
    Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SeverityPill from '../Shared/SeverityPill';
import { Link } from 'react-router-dom';

const LatestOrders = ({ productsResponse }) => (
    <Card>
        <CardHeader title="Products at Inventory Risk" />
        <Box >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Id
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Inventory
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productsResponse && productsResponse.content.map((product) => (
                        <TableRow key={product.id}>
                            <TableCell>
                                {product.id}
                            </TableCell>
                            <TableCell>
                                {product.name}
                            </TableCell>
                            <TableCell>
                                <SeverityPill
                                    color={((parseInt(product.inventorySize) >= 100) && 'success')
                                        || ((parseInt(product.inventorySize) <= 50) && 'error')
                                        || 'warning'}
                                >
                                    {product.inventorySize}
                                </SeverityPill>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>

        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >
            <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small" />}
                size="small"
                component={Link} to={`/products`}
                variant="text"
            >
                View all
            </Button>
        </Box>
    </Card>
);

export default LatestOrders;